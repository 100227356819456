// src/components/ExploreCollectionCarousel.js

import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';

// Import images
import c1 from '../assets/carousel/c1.jpg';
import c2 from '../assets/carousel/c2.jpg';
import c3 from '../assets/carousel/c3.jpg';
import c4 from '../assets/carousel/c4.jpg';
import c5 from '../assets/carousel/c5.jpg';
import c6 from '../assets/carousel/c6.jpg';
import c7 from '../assets/carousel/c7.jpg';

const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 40px 0;
  background-color: #FFF3DE;
  position: relative;
`;

const CarouselTitle = styled.h2`
  font-family: 'Cinzel', serif;
  text-align: center;
  margin-bottom: 20px;
`;

const CarouselTrack = styled.div`
  display: flex;
  transition: transform 1s ease;
`;

const CarouselItem = styled.div`
  flex: 0 0 33.33%;
  max-width: 33.33%;
  position: relative;
  padding: 10px;
`;

const CarouselImage = styled.img`
  width: 30vw;
  height: 30vw;
  object-fit: cover;
  border-radius: 5px;
`;

const PlusIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
`;

const ViewFullCollectionButton = styled(Button)`
  && {
    margin-top: 40px;
    background-color: transparent;
    border: 2px solid #f4b642;
    color: #f4b642;
    text-transform: none;
    padding: 10px 20px;
    font-weight: bold;

    &:hover {
      background-color: #f4b642;
      color: #fff;
    }
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const ModalImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
`;

const ExploreCollectionCarousel = () => {
  const images = [c1, c2, c3, c4, c5, c6, c7];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalImage, setModalImage] = useState(null);
  const carouselRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [images.length]);

  useEffect(() => {
    const trackWidth = carouselRef.current.scrollWidth;
    const itemWidth = trackWidth / images.length;
    carouselRef.current.style.transform = `translateX(${-currentIndex * itemWidth}px)`;
  }, [currentIndex, images.length]);

  const openModal = (image) => {
    setModalImage(image);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  return (
    <CarouselContainer>
      <CarouselTitle>Explore the Collection</CarouselTitle>
      <CarouselTrack ref={carouselRef}>
        {images.concat(images.slice(0, 3)).map((image, index) => (
          <CarouselItem key={index}>
            <figure>
              <CarouselImage src={image} alt={`Gallery Image ${index + 1}`} onClick={() => openModal(image)} />
              <PlusIcon onClick={() => openModal(image)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff">
                  <path d="M12 5v14m-7-7h14" stroke="#fff" strokeWidth="2" strokeLinecap="round" />
                </svg>
              </PlusIcon>
            </figure>
          </CarouselItem>
        ))}
      </CarouselTrack>
      {modalImage && (
        <ModalOverlay onClick={closeModal}>
          <ModalImage src={modalImage} alt="Modal View" />
        </ModalOverlay>
      )}
      <div style={{ textAlign: 'center' }}>
        <ViewFullCollectionButton href="gallery">
          View Full Collection ➞
        </ViewFullCollectionButton>
      </div>
    </CarouselContainer>
  );
};

export default ExploreCollectionCarousel;