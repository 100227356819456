// src/components/AboutPage.js

import React from 'react';
import styled from 'styled-components';

// Import image
import bannerImage from '../assets/aboutPage/gallery-banner.jpg';

const AboutContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
`;

const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background: url(${bannerImage}) center/cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BannerTitle = styled.h1`
  font-family: 'Cinzel', serif;
  font-size: 3rem;
  color: #ffffff;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
`;

const AboutContent = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.8;
  text-align: left;
`;

const SectionTitle = styled.h2`
  font-family: 'Cinzel', serif;
  font-size: 2rem;
  margin-top: 40px;
  color: #333;
`;

const Paragraph = styled.p`
  margin-bottom: 20px;
  font-size: 1.1rem;
  color: #555;
`;

const AboutPage = () => {
  return (
    <AboutContainer>
      <Banner>
        <BannerTitle>About</BannerTitle>
      </Banner>
      <AboutContent>
        <SectionTitle>Early Life and Education</SectionTitle>
        <Paragraph>
          Jean English was born in Limerick and studied at the Limerick School of Art and Design. She has lived in Dublin and been a professional artist for most of her working life.
        </Paragraph>

        <SectionTitle>Professional Work</SectionTitle>
        <Paragraph>
          Jean has worked for publishers in Ireland, England, and the United States, designing Greeting Cards, Books, Placemats, Calendars, and much more.
        </Paragraph>

        <SectionTitle>Artistic Style and Portfolio</SectionTitle>
        <Paragraph>
          Her portfolio includes oil and watercolor paintings, mixed media, and detailed drawings. Jean now lives in Dunmore East.
        </Paragraph>

        <SectionTitle>Heritage and Influence</SectionTitle>
        <Paragraph>
          Jean is part of a proud lineage of Irish romantic landscape painters, capturing the essence and beauty of Ireland's natural scenery through her work.
        </Paragraph>
      </AboutContent>
    </AboutContainer>
  );
};

export default AboutPage;
