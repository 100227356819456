// src/components/aboutSection.js

import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button'; // Import Material UI button
import aboutImage from '../assets/about/about_img.png'; // Update path and format if necessary

const AboutSectionBackground = styled.section`
  width: 100%;
  background-color: #F7F7F7; /* Background color spanning full width */
  padding: 40px 0; /* Add padding for top/bottom to provide spacing */
`;

const AboutSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1200px; /* Limit the width of the content */

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const AboutImage = styled.img`
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-right: 40px;
    margin-bottom: 0;
  }
`;

const AboutTextWrapper = styled.div`
  max-width: 600px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const AboutHeading = styled.h2`
  font-family: 'Cinzel', serif; /* Replace with your font */
  font-weight: 300;
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const AboutParagraph = styled.p`
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const LearnMoreButton = styled(Button)`
  && {
    background-color: transparent;
    border: 2px solid #f4b642; /* Gold border similar to reference */
    color: #f4b642;
    text-transform: none;
    padding: 10px 20px;
    font-weight: bold;

    &:hover {
      background-color: #f4b642; /* Solid fill on hover */
      color: #fff;
    }
  }
`;

const AboutSection = () => {
  return (
    <AboutSectionBackground>
      <AboutSectionWrapper>
        <AboutImage src={aboutImage} alt="About Jean English Art" />
        <AboutTextWrapper>
          <AboutHeading>About Us</AboutHeading>
          <AboutParagraph>
            Jean English was born in Limerick and studied at the Limerick School of Art and Design. She has lived in Dublin and been a professional artist for most of her working life.
          </AboutParagraph>
          <AboutParagraph>
            Jean has worked for publishers in Ireland, England and the United States, designing Greeting Cards, Books, Placemats, Calendars and much more.
          </AboutParagraph>
          <LearnMoreButton href="about">Learn More ➞</LearnMoreButton>
        </AboutTextWrapper>
      </AboutSectionWrapper>
    </AboutSectionBackground>
  );
};

export default AboutSection;
