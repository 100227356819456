// src/components/ContactPage.js

import React from 'react';
import styled from 'styled-components';
import { TextField, Button } from '@mui/material';
import { LocationOn, Phone, Email } from '@mui/icons-material';
import bannerImage from '../assets/aboutPage/gallery-banner.jpg';

const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background: url(${bannerImage}) center/cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BannerTitle = styled.h1`
  font-family: 'Cinzel', serif;
  font-size: 3rem;
  color: #ffffff;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
`;

const ContactContainer = styled.div`
  width: 100%;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  max-width: 1200px;
  margin: 0 auto;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ContactInfo = styled.div`
  flex: 1;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  width: 100%;
  max-width: 500px;

  p {
    font-size: 1.1rem;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 40px;
    }

    a {
      text-decoration: none;
      color: #333;
      font-weight: bold;

      &:hover {
        color: #f4b642;
      }
    }
  }
`;

const ContactForm = styled.div`
  flex: 1;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  margin-top: 40px;
  @media (min-width: 768px) {
    margin-left: 20px;
    margin-left: 20px;
    margin-top: 0;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #f4b642;
`;

const StyledButton = styled(Button)`
  && {
    background-color: transparent;
    border: 2px solid #f4b642;
    color: #f4b642;
    text-transform: none;
    padding: 10px 20px;
    font-weight: bold;
    margin-top: 20px;

    &:hover {
      background-color: #f4b642;
      color: #fff;
    }
  }
`;

const ContactPage = () => {
  return (
    <>
      <Banner>
        <BannerTitle>Gallery</BannerTitle>
      </Banner>
      <ContactContainer>
      {/* Contact Info */}

      <ContactInfo>
        <p>
          <IconWrapper>
            <LocationOn />
          </IconWrapper>
          <strong>Address:</strong>
          <br />
          <span>
            Woodview
            <br />
            Coxtown
            <br />
            Dunmore East
            <br />
            Co. Waterford
            <br />
            X91 PP93
          </span>
        </p>
        <ul>
          <li>
            <IconWrapper>
              <Phone />
            </IconWrapper>
            <strong>Phone:</strong>&nbsp;
            <a href="tel:+353868902920">+353 86 8902920</a>
          </li>
          <li>
            <IconWrapper>
              <Email />
            </IconWrapper>
            <strong>Email:</strong>&nbsp;
            <a href="mailto:jeanenglisharts@gmail.com">jeanenglisharts@gmail.com</a>
          </li>
        </ul>
      </ContactInfo>

      {/* Contact Form */}
      <ContactForm>
        <h2>Get in touch</h2>
        <form>
          <TextField
            fullWidth
            label="Your Name"
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Your Email"
            type="email"
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Your Phone No."
            type="tel"
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Your Message..."
            multiline
            rows={4}
            variant="outlined"
            margin="normal"
          />
          <StyledButton type="submit" variant="contained">
            Submit
          </StyledButton>
        </form>
      </ContactForm>
    </ContactContainer>
    </>
   
  );
};

export default ContactPage;
