// src/components/home.js

import React from 'react';
import IntroSection from './introSection.js';
import AboutSection from './aboutSection.js';// import GallerySection from './gallerySection';
import ExploreCollectionCarousel from './miniGallerySection.js';
const Home = () => {
  return (
    <div>
      <IntroSection />
      <AboutSection />
      <ExploreCollectionCarousel />
    </div>
  );
};

export default Home;