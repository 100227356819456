// src/components/GalleryPage.js

import React, { useState } from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import bannerImage from '../assets/aboutPage/gallery-banner.jpg';

// Efficiently import all images
function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(require.context('../assets/imageAssets', false, /\.jpg$/));

const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background: url(${bannerImage}) center/cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BannerTitle = styled.h1`
  font-family: 'Cinzel', serif;
  font-size: 3rem;
  color: #ffffff;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
`;

const GalleryContainer = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
`;

const GalleryItem = styled.div`
  width: 100%;
  padding-top: 75%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const GalleryImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  padding: 20px;
  box-sizing: border-box;
`;

const ModalImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90vw;  // Allow the image to take up to 90% of the screen width
  max-height: 90vh; // Allow the image to take up to 90% of the screen height
`;



const ModalImage = styled.img`
  width: auto;          // Automatically determine the width to maintain aspect ratio
  height: auto;         // Automatically determine the height to maintain aspect ratio
  max-width: 75vw;      // Restrict the width to a maximum of 90% of the viewport width
  max-height: 75vh;     // Restrict the height to a maximum of 90% of the viewport height
  border-radius: 8px;   // Keep rounded corners
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5); // Add a shadow for visual appeal
`;



const ArrowButton = styled(IconButton)`
  position: absolute;
  top: 10%;
  transform: translateY(-25%);
  color: #ffffff;
  z-index: 5001;
  padding: 10px;
  svg {
    font-size: 2.5rem;
  }

  @media (min-width: 768px) {
    padding: 15px;
    svg {
      font-size: 2rem;
    }
  }
`;

const GalleryPage = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openModal = (index) => {
    setSelectedImageIndex(index);
  };

  const closeModal = () => {
    setSelectedImageIndex(null);
  };

  const showPreviousImage = (e) => {
    e.stopPropagation();
    setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const showNextImage = (e) => {
    e.stopPropagation();
    setSelectedImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <>
      <Banner>
        <BannerTitle>Gallery</BannerTitle>
      </Banner>
      <GalleryContainer>
        {images.map((image, index) => (
          <GalleryItem key={index} onClick={() => openModal(index)}>
            <GalleryImage
              src={image}
              alt={`Gallery Image ${index + 1}`}
            />
          </GalleryItem>
        ))}
      </GalleryContainer>

      {selectedImageIndex !== null && (
        <Modal onClick={closeModal}>
          <ModalImageWrapper>
            <ArrowButton onClick={showPreviousImage}>
              <ArrowBackIosNewIcon />
            </ArrowButton>
            <ModalImage src={images[selectedImageIndex]} alt="Selected Gallery" />
            <ArrowButton onClick={showNextImage}>
              <ArrowForwardIosIcon />
            </ArrowButton>
          </ModalImageWrapper>
        </Modal>
      )}
    </>
  );
};

export default GalleryPage;