// src/components/header.js

import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Box, Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';

// Import the logo from the public folder using process.env.PUBLIC_URL
const logo = `${process.env.PUBLIC_URL}/img/logo.png`;

const StyledAppBar = styled(AppBar)`
  transition: height 0.3s ease, box-shadow 0.3s ease;
  background-color: white !important;
  box-shadow: ${({ scroll }) => (scroll ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none')};
  height: ${({ scroll }) => (scroll ? '60px' : '85px')};
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;

const Logo = styled.img`
  height: ${({ scroll }) => (scroll ? '50px' : '70px')};
  transition: height 0.3s ease;
`;

const Links = styled(Box)`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  gap: 20px;

  a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    transition: color 0.3s ease, font-size 0.3s ease;
    font-size: ${({ scroll }) => (scroll ? '0.9rem' : '1.1rem')};
    &:hover {
      color: gold;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const SocialLinks = styled(Box)`
  display: flex;
  gap: 10px;

  a {
    text-decoration: none;
    color: black;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileMenuButton = styled(IconButton)`
  color: black;
`;

const MobileMenuLinks = styled(List)`
  a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    transition: color 0.3s ease;
    &:hover {
      color: gold;
    }
  }
`;

const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Toggle the scroll state when the user scrolls
  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const list = () => (
    <MobileMenuLinks
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {['Home', 'About', 'Gallery', 'Contact'].map((text) => (
          <ListItem button key={text} component={Link} to={text === 'Home' ? '/' : `/${text.toLowerCase()}`}>
           <ListItemText primary={text} />
          </ListItem>

        ))}
      </List>
    </MobileMenuLinks>
  );

  return (
    <StyledAppBar position="fixed" scroll={scroll}>
      <Toolbar style={{ justifyContent: 'space-between' }}>
        {/* Logo with overhang */}
        <IconButton edge="start" color="inherit" aria-label="logo">
          <Link to="/">
            <Logo src={logo} alt="Gallery J Logo" scroll={scroll} />
          </Link>
        </IconButton>

        {/* Navigation Links */}
        <Links scroll={scroll}>
          <Link to="/">HOME</Link>
          <Link to="/about">ABOUT</Link>
          <Link to="/gallery">GALLERY</Link>
          <Link to="/contact">CONTACT</Link>
        </Links>

        {/* Social Media Links */}
        <SocialLinks>
          <a href="https://instagram.com" target="_blank" rel="noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://facebook.com" target="_blank" rel="noreferrer">
            <i className="fab fa-facebook"></i>
          </a>
        </SocialLinks>

        {/* Mobile Menu Button */}
        {isMobile && (
          <MobileMenuButton edge="end" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </MobileMenuButton>
        )}

        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
