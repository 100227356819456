// src/components/Layout.js

import React from 'react';
import styled from 'styled-components';
import Header from './header';
import Footer from './footer';

// Styled-components for layout and responsiveness
const LayoutWrapper = styled.div`
  width: 100%;
  margin: 0; /* Remove any default margin */
  padding-top: 10vh; /* Remove any default padding */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
`;

const Layout = ({ children }) => {
  return (
    <LayoutWrapper>
      <Header />
      <MainContent>
        {children} {/* All the main content of the page */}
      </MainContent>
      <Footer />
    </LayoutWrapper>
  );
};

export default Layout;