// src/components/footer.js

import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import styled from 'styled-components';

// Import logo (using process.env.PUBLIC_URL if it's in the public folder)
const logo = `${process.env.PUBLIC_URL}/img/logo.png`;

const FooterContainer = styled(Box)`
  background-color: #1b1b1b;
  color: #fff;
  padding: 40px 20px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 60px 100px;
  }
`;

const FooterLogo = styled.img`
  height: 100px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const ContactDetails = styled(Box)`
  text-align: center;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    text-align: left;
    margin-bottom: 0;
  }

  a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      color: #f4b642;
    }
  }
`;

const FooterNav = styled(Box)`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;

  @media (min-width: 768px) {
    margin-top: 0;
    justify-content: flex-start;
  }

  a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      color: #f4b642;
    }
  }
`;

const SocialIcons = styled(Box)`
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 0;
  }

  a {
    color: #fff;
    font-size: 1.5rem;
    &:hover {
      color: #f4b642;
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      {/* Footer Logo */}
      <FooterLogo src={logo} alt="Gallery J Logo" />

      {/* Contact Details */}
      <ContactDetails>
        <Typography variant="body1">
          Woodview Coxtown, Dunmore East, Co. Waterford, X91 PP93
        </Typography>
        <Typography variant="body1">
          Phone: <a href="tel:+353868902920">+353 86 890 2920</a> |
          <a href="mailto:jeanenglisharts@gmail.com"> jeanenglisharts@gmail.com</a>
        </Typography>
      </ContactDetails>

      {/* Footer Navigation Links */}
      <FooterNav>
        <Link href="#home">Home</Link>
        <Link href="#about">About</Link>
        <Link href="#gallery">Gallery</Link>
        <Link href="#contact">Contact</Link>
      </FooterNav>

      {/* Social Media Links */}
      <SocialIcons>
        <Link href="https://instagram.com" target="_blank" rel="noreferrer">
          <i className="fab fa-instagram"></i>
        </Link>
        <Link href="https://facebook.com" target="_blank" rel="noreferrer">
          <i className="fab fa-facebook"></i>
        </Link>
      </SocialIcons>
    </FooterContainer>
  );
};

export default Footer;