// src/components/introSection.js

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Import images from assets/intro directory
import image1 from '../assets/intro/1.jpg';
import image2 from '../assets/intro/2.jpg';
import image3 from '../assets/intro/3.jpg';
import image4 from '../assets/intro/4.jpg';
import image5 from '../assets/intro/5.jpg';

// Array of imported images
const images = [image1, image2, image3, image4, image5];

const IntroSectionWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
`;

const SlideImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover container */
  transition: opacity 1s ease-in-out;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;

const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%; /* Adjust height to control how much of the image is covered */
  background: linear-gradient(to top, rgba(0, 0, 0, .8), transparent);
  pointer-events: none; /* Allows clicks to pass through */
`;

const IntroText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  font-family: 'Cinzel', serif; /* Replace with your font */
  font-weight: 5; /* Slimmer font weight */
  font-size: 3rem;

  @media (min-width: 768px) {
    font-size: 10rem; /* Larger font for desktop */
  }
`;

const IntroSection = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  return (
    <IntroSectionWrapper>
      {images.map((image, index) => (
        <SlideImage
          key={index}
          src={image}
          alt={`Gallery J Art Slide ${index + 1}`}
          isVisible={index === currentImageIndex}
        />
      ))}
      {/* Overlay to add gradient effect */}
      <GradientOverlay />
      <IntroText>
        Gallery J Art
      </IntroText>
    </IntroSectionWrapper>
  );
};

export default IntroSection;
